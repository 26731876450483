/** @format */

.marquee {
  --gap: 1rem;
  display: flex;
  gap: var(--gap);
  height: 100%;
  overflow: hidden;
  position: relative;
  user-select: none;
  min-height: 20vh;
}

.content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
}

.content img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.content>* {
  flex: 0 0 auto;
  color: #fff;
  margin: 2px;
  border-radius: 10px;
}

.item {
  display: flex;
  filter: brightness(33%);
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 10vw;

  &:hover {
    filter: brightness(100%);
  }
}

ul {
  padding-left: 0;
}

.enable-animation {
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  min-height: 500px;
  min-width: 500px;
  width: 100%;
  z-index: 0;
}

.enable-animation .content {
  animation: scroll 60s linear infinite;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}