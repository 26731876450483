#uploadContainer {
    background-color: white;
    width: 700px;
    height: 540px;
    border-radius: 25px;
    overflow: hidden;
    margin: 20px;
}

#uploadContainer .header {
    background-color: black;
    width: 100%;
    padding: 10px;
    height: 100px;
    text-align: center;
}

#uploadContainer .header img {
    /* width: 100%; */
    height: 80px;
}

#uploadContainer .body {
    width: 100%;
    padding: 0px 20px;
    font-size: 2rem;
}
#uploadContainer .body ol {
    list-style-type: none;
    padding:1rem;
    counter-reset: elementcounter;
    
}

#uploadContainer ol li:before {
    content: "Step " counter(elementcounter) ": ";
    counter-increment: elementcounter;
    font-weight: bold;
}

#uploadContainer ol li {
    padding-bottom:'2rem'
}


.filepond--drop-label {
    height: 100% !important;
    display: flex;
    /* background-color: #EAF5FF; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 2rem !important;
    width: 100%;
    /* border-radius: 25px;
    border-color: #B2DAFF;
    border-style: dashed;
    border-width: 1px; */
}
/* 
.filepond--wrapper {
    font-size: 2rem !important;
    width: 100%;
    background-color: white;
    margin: auto;
}*/

.filepond--root {
    font-size: 2rem !important;
    height: 200px !important;
    display: flex;
    overflow: hidden;
    background-color: #EAF5FF !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 25px;
    border-color: #B2DAFF;
    border-style: dashed;
    border-width: 1px;
}
.filepond--panel-root {
    background-color: #EAF5FF !important;
}

/* .filepond--item {
    font-size: 2rem !important;
    height: 45px
}  */